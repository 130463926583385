/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
.max-250 {
  display: block;
  max-width: 250px;
  margin-left: auto;
  margin-right: auto;
}

.max-500 {
  display: block;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.max-600 {
  display: block;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.max-700 {
  display: block;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.max-800 {
  display: block;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.max-900 {
  display: block;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}

.max-1000 {
  display: block;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.bold {
  font-weight: bold !important;
}

.flex {
  display: flex; /* or inline-flex */
}

ion-content {
  --padding-bottom: 100px;
}

ion-list {
  --background: transparent !important;
  background-color: transparent !important;
}

ion-item {
  --background: transparent !important;
  background-color: transparent !important;
}

ion-card {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

ion-card-content {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.divider {
  width: 100%;
  border-bottom: dotted rgb(207, 207, 207) 1px;
}

.divider-solid {
  width: 100%;
  border-bottom: solid rgb(207, 207, 207) 1px;
}

.toas-connect {
  --background: var(--ion-color-yellow);
  --color:black;
  --max-width:175px;
}

.toasty-lock {
  --background: var(--ion-color-success) !important;
  --color:white;
}

.inactive {
  pointer-events: none !important;
  opacity: 0.7 !important;
  --color:grey !important;
  color: grey !important;
}
.inactive ion-label {
  --color:grey !important;
  opacity: 0.7 !important;
}
.inactive ion-icon {
  --color:grey !important;
  opacity: 0.7 !important;
}

.inactiveMenu {
  pointer-events: none !important;
  opacity: 0.7 !important;
  --color:grey !important;
  color: grey !important;
}
.inactiveMenu ion-label {
  --color:grey !important;
  opacity: 0.7 !important;
}
.inactiveMenu ion-icon {
  --color:grey !important;
  opacity: 0.7 !important;
}

.hover {
  cursor: pointer;
}

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}

th {
  background-color: var(--ion-color-light) !important;
}

ion-alert.alert-ion-date {
  --min-width: 350px !important;
  --min-height: 450px !important;
}
ion-alert.alert-ion-date .sc-ion-alert-ios {
  --min-width: 350px !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}